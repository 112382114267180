import React, { useEffect, useRef, useState } from 'react';
import { getFontColor } from './helper';

const DEFAULT_COLORS = ['#00ffff', '#ffff00', '#800080'];

const ResultTile = ({ tiles, currentRosFeature, isAnalytics = false }) => {
  const scrollRef = useRef(null);
  let currentTileColor = useRef(null);
  const [columns, setColumns] = useState(Array.from({ length: 3 }, () => []));

  useEffect(() => {
    const result = tiles.map((tile) => {
      return {
        ...tile,
        backgroundColor: getRandomColor()
      };
    });

    const columns = [[], [], []];
    result.forEach((tile, index) => {
      columns[index % 3].push(tile);
    });

    setColumns(columns);
  }, [tiles]);

  const settings = currentRosFeature?.settings || currentRosFeature?.setting;
  const { talkingTileBgColor1 = '', talkingTileBgColor2 = '', talkingTileBgColor3 = '' } = currentRosFeature?.color || currentRosFeature?.colors;

  const getRandomColor = () => {
    const colors = talkingTileBgColor1 && talkingTileBgColor2 && talkingTileBgColor3 ? [talkingTileBgColor1, talkingTileBgColor2, talkingTileBgColor3] : DEFAULT_COLORS;
    const index = colors.indexOf(currentTileColor.current);
    const randomIndex = Math.floor(Math.random() * colors.length);
    currentTileColor.current = colors[randomIndex === index ? (randomIndex + 1) % colors.length : randomIndex];
    return currentTileColor.current;
  };
  const fontFamily = settings?.FontStyle || 'monospace';

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [columns, tiles]);

  if (tiles.length === 0) {
    return <div className='flex justify-center items-center text-center text-2xl font-Inter'>No comments to show</div>;
  }

  return (
    <div className={`${isAnalytics ? '' : 'h-screen'} flex flex-col`}>
      <div ref={scrollRef} className="flex-1 overflow-y-auto p-4">
        <div className="flex gap-4 min-h-full md:mb-1/10 lg:mb-2.5/10 2xl:mb-1/10">
          {columns.map((column, columnIndex) => (
            <div key={columnIndex} className="flex-1 flex flex-col justify-end gap-4">
              {column.map((tile) => <TileContent tile={tile} fontFamily={fontFamily} showUsername={settings?.showUserName} />)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const TileContent = ({ tile, fontFamily, showUserName = true }) => {
  const fontColor = getFontColor(tile?.backgroundColor || 'black');
  const word = tile.words || tile.body;
  const truncatedText = word.length > 300 ? `${word.substring(0, 300)}...` : word;

  return (
    <div
      key={tile.id}
      style ={{
        backgroundColor: tile.backgroundColor,
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      }}
      className="w-full  flex flex-col justify-between  px-4 py-2   rounded-lg break-words   overflow-hidden"
    >
      <div className='break-words mt-10  hyphens-auto break-all text-center justify-center   '>
        <p className=" lg:text-xl xl:text-2xl " style ={{ fontFamily, lineHeight: 1.2, color: fontColor }}>{truncatedText}</p>
      </div>
      <div className='self-end flex justify-end my-2.5'>
        {tile.username && showUserName && <Username username={tile.username} fontFamily={fontFamily} />}
      </div>
    </div>
  );
};

const Username = ({ username, fontFamily }) => {
  const truncatedUsername = username.length > 30 ? `${username.substring(0, 30)}...` : username;
  return (
    <div
      className="self-end mt-2.5 px-2.5 py-1.5 rounded-lg"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <p className="text-white m-0 text-base font-bold" style={{ fontFamily }}>
        {truncatedUsername}
      </p>
    </div>
  );
};

export default ResultTile;
